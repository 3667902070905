// global style guide
@use "sass:map";

.profile-view {
  margin: 0 auto;
  padding-top: 30px;
  width: 60%;
}

.profile-titlebar {
  @include set-display-flex(center, space-between);
  padding: 16px 24px;
  background-color: map.get($theme-colors, "white-f1");
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  & .profile-title {
    font-size: 24px;
    font-weight: 600;
  }
  & .profile-buttons {
    display: flex;
    gap: 4px;

    & .editprofile-link {
      box-shadow: map.get($theme-colors, "black-alhpa-25");
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
}

.edit-profile-container {
  width: 60%;
  margin: 50px auto;
}

.avatar-upload {
  position: relative;
  max-width: 260px;
  margin: 50px auto;
  @include set-display-flex(flex-start, center);

  .avatar-edit {
    position: absolute;
    // right: 120px;
    z-index: 1;
    top: 110px;
    input {
      display: none;
      + label {
        display: inline-block;
        content: "Select Photo";
        margin-bottom: 0;
        background: map.get($theme_colors, "white");
        padding: 4px 15px;
        border: 1px solid map.get($theme-colors, "gray-d9");
        box-shadow: 0px 2px 4px 0px map.get($theme-colors, "black-alhpa-12");
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          border-color: map.get($theme_colors, "primary-text-40");
          color: map.get($theme-colors, "primary-text-40");
        }
      }
    }

    & .edit-profileImage-button {
      margin-bottom: 50px;
    }
  }
  .avatar-preview {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
    border: 6px solid map.get($theme-colors, "white-f8");
    box-shadow: 0px 2px 4px 0px map.get($theme-colors, "black-alhpa-10");

    & .imagePreview {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.edit-profileImage-button {
  text-align: center;
  margin: 4px 0px;
}

.ant-modal.change-password-modal {
  & .ant-modal-content {
    padding: 24px;
    border-radius: 16px;
  }
}
