@use "sass:map";

.img-message {
  position: relative;
  width: fit-content;

  & .messages-img {
    width: 214px;
    height: 214px;
    float: right;
  }

  & .message-double-img {
    width: 274px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & .image {
      width: 137px;
      height: 137px;
    }
  }

  & .message-three-img {
    position: relative;
    width: 274px;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & .image {
      z-index: 0;
      width: 137px;
      height: 137px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      & .more-button-dark {
        width: 137px;
        height: 137px;
        background-color: map.get($theme-colors, "black-alhpa-41");
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      & .more-img-button {
        width: 137px;
        height: 137px;
        font-size: 36px;
        color: map.get($theme-colors, "white");
        font-weight: 700;
        z-index: 9;
      }
    }
  }
}

.video-player-control {
  width: 50vw;
  height: 70vh;
  object-fit: inherit;
  border-radius: 30px;
}
.react-lightbox-component-icon-left {
  left: 15% !important;
}
.react-lightbox-component-icon-right {
  right: 15% !important;
}
.video-close-icon {
  position: absolute;
  top: 10%;
  z-index: 50;
  left: 75%;
  border: 0;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 100%;
  color: map.get($theme-colors, "orange-text-ff");
  box-shadow: none;

  &:hover {
    color: map.get($theme-colors, "orange-text-ff");
  }
}
