@use "sass:map";

.ant-modal.profile-modal {
  & .ant-modal-content {
    border-radius: 30px;
    padding: 24px;
    width: 420px;
    height: fit-content;

    & .ant-modal-body {
      padding: 0;

      & .user-profile-info {
        @include set-display-flex(center, center, 4px, column);

        & .user-profile-fullname {
          font-size: 30px;
          font-weight: 600;
          color: map.get($theme-colors, "black");
          text-transform: capitalize;
        }

        & .user-profile-email {
          font-size: 12px;
          color: map.get($theme-colors, "primary-text-00");
        }
      }

      & .ant-divider {
        margin: 12px 0;
        border-color: map.get($theme-colors, "gray-text-9b");
      }

      & .profile-modal-options {
        padding: 12px 24px;
        @include set-display-flex(flex-start, flex-start, 16px, column);

        & .profile-option {
          @include set-display-flex(center, flex-start, 8px);
          font-size: 18px;
          font-weight: 600;
        }

        & .profile-notification-option {
          width: 100%;
          @include set-display-flex(center, space-between);
        }
        Button.profile-option {
          padding: 0;
          border: none;
          background: transparent;
          box-shadow: none;

          &:hover,
          &:focus {
            padding: 0;
            border: none;
            background-color: transparent;
            color: map.get($theme-colors, "black");
            box-shadow: none;
          }
        }
      }

      & .logout-modal-btn {
        @include set-display-flex(center, center);
        margin: 32px auto;

        & Button {
          width: 50%;
        }
      }
    }
  }
}
