// global style guide
@use "sass:map";
.chatting-home {
  height: 100%;
  position: relative;
  background-color: map.get($theme-colors, "white");

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: map.get($theme-colors, "gray-text-c4");
  }

  @include media("<=tablet") {
    width: 100vw;
  }

  & .chatting-content {
    width: 100%;
    @include set-display-flex(flex-start, flex-end, 32px, column);
    height: 100%;
    min-height: 82vh;

    & .previous-btn-container {
      padding-top: 20px;
      width: 100%;
      @include set-display-flex(flex-start, center);

      & .previous-btn {
        padding: 0 8px;
        background-color: map.get($theme-colors, "white-f1");
        height: fit-content;
        border-radius: 15px;
      }
    }

    & .all-messages-content {
      @include set-display-flex(flex-start, flex-end, 32px, column);
      width: 100%;
      margin-bottom: 20px;
      display: block;
      height: 100%;
      padding: 16px 64px;

      & .chatting-date {
        justify-content: center;
        padding: 0 20px;
        color: map.get($theme-colors, "gray-text-71");
        font-size: 14px;
        text-align: center;
      }

      @include media("<=tablet") {
        width: 100vw;
        padding: 0;
      }
    }

    & .message-choose-card {
      width: 50%;
      margin: 20px auto;
      background-color: map.get($theme-colors, "white-f1");
      border-radius: 16px;
      padding: 16px;
      text-align: center;

      & .message-choose-text {
        font-size: 20px;
        font-weight: 600;
      }

      & .message-choose-buttons {
        @include set-display-flex(center, center, 20px);
        margin: 20px auto;

        & > button {
          border-radius: 4px;
          border: none;
          padding: 8px 32px;
          font-size: 18px;
          font-weight: 500;
        }

        & > .reject-button {
          background-color: map.get($theme-colors, "black");
          color: map.get($theme-colors, "white");
        }

        & > .accept-button {
          background-color: map.get($theme-colors, "black");
          color: map.get($theme-colors, "white");
        }
      }
    }

    @include media("<=tablet") {
      width: 100vw;
      padding: 0;
    }
  }

  @include media("<=tablet") {
    width: 100vw;
    // height: 100vh;

    & .chatting-content {
      overflow: auto;
    }
  }
}

.chatting-bottom {
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 1000;
  background-color: map.get($theme-colors, "white");
  .user-typing {
    @include set-display-flex(center, flex-start, 12px);
    margin-left: 88px;

    & .typing {
      @include set-display-flex(center, center);

      & .circle {
        display: block;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: map.get($theme-colors, "gray-text-8d");
        margin: 3px;
        animation: typing 1500ms ease-in-out infinite;
        animation-delay: 4500ms;

        &:nth-child(1) {
          animation-delay: 0ms;
        }

        &:nth-child(2) {
          animation-delay: 333ms;
        }

        &:nth-child(3) {
          animation-delay: 666ms;
        }

        &:nth-child(4) {
          animation-delay: 999ms;
        }

        &:nth-child(5) {
          animation-delay: 1332ms;
        }
      }
    }
  }

  .message-input-container {
    width: 100%;
    @include set-display-flex(center, center, 0.5rem);

    & .message-input {
      border-radius: 999px;
      padding: 8px;

      & button {
        display: block;
        border: none;
        padding: 7px;
        background: none;
      }
    }
  }

  @include media("<=tablet") {
    padding: 0.5rem 0;
    right: 0;
    width: 100vw;
  }
}

@keyframes typing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.message-list {
  @include set-display-flex(flex-start, flex-end, 8px, column);
  width: 100%;
}
.sayhi-card {
  width: 300px;
  height: 180px;
  background-color: map.get($theme-colors, "white-f1");
  margin: 20px auto;
  border-radius: 12px;
  @include set-display-flex(center, space-around, 0, column);

  & .sayhi-emoji {
    width: 50px;
    height: 50px;
  }

  & .sayhi-btn {
    width: 150px;
  }
}
