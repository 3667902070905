// global style guide
@use "sass:map";

// Sidebar Component Style
.sider-component {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

// Home content without sidebar
.content-area {
  margin-left: 462px;
  overflow: initial;
  height: 100vh;

  @include media("<=tablet") {
    width: 99vw;
    margin-left: 0;
    height: 93vh;
    overflow-x: hidden;
    // display: none;
    overflow-y: scroll;
  }
}

// Welcome home content css
.home-content {
  height: 100%;
  padding: 24px;
  text-align: center;
  @include set-display-flex(center, center, 10, column);

  & .start-chat {
    font-size: 36px;
    font-weight: 600;
    color: map.get($theme-colors, "gray-text-98");
    padding-top: 10px;
  }
}
