@use "sass:map";

.user-profile-info {
  width: 100%;
  @include set-display-flex(center, center, 8px, column);

  & .user-profile-name {
    font-size: 20px;
    font-weight: 600;
    line-height: 40px;
    text-transform: capitalize;
  }

  & .user-profile-email {
    color: map.get($theme-colors, "primary-text-00");
  }
}

.user-profile-switch {
  margin: 0.5rem;
  @include set-display-flex(center, space-between);

  & .user-profile-switch-title {
    font-weight: 600;
  }
}

.user-profile-function {
  margin: 0.5rem;
  & button {
    font-weight: 600;
    padding: 0;
    display: block;
    background: transparent;
    color: map.get($theme-colors, "orange-text-ff");

    &:hover,
    &:focus {
      background: transparent;
    }
    &:first-child {
      color: map.get($theme-colors, "black");
    }
  }
}

.profile-divider {
  border-color: map.get($theme-colors, "black");
  margin: 1rem 0;
}
