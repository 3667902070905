@use "sass:map";

.login-section {
  background-color: map.get($theme-colors, "primary-bg");
  height: 100vh;
  position: relative;
}

.login-logo {
  @include set-display-flex(center, center, 0, column);
  width: 100%;

  & > h1 {
    @include media("<=tablet") {
      font-size: 32px;
      text-align: center;
    }
  }
  .title-slogun {
    color: map.get($theme-colors, "white");
    font-size: 20px;
  }

  & > .login-logo-img {
    inline-size: 100%;
    object-fit: contain;
    height: 204px;
    aspect-ratio: 1/1;

    @include media("<=tablet") {
      height: 100px;
    }
  }
}

.login-form-container {
  @include set-display-flex(center, center, 0, column);
  background-color: map.get($theme-colors, "white");
  height: 100vh;
  width: 100%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;

  @include media("<=tablet-xl") {
    justify-content: start;
    padding-top: 16px;
    border-radius: 0;
  }

  & .login-form-title {
    color: map.get($theme-colors, "primary-text-125");
    text-align: left;
  }

  & .havenotaccount {
    padding-top: 10px;
    color: map.get($theme-colors, "black-alhpa-60");
    font-size: 16px;
  }

  & form.login-form.ant-form {
    width: 60%;

    @include media("<=tablet") {
      width: 90%;
    }

    & > * {
      width: 100%;
    }

    & > .forgot-password {
      text-align: right;
    }
  }

  .login-button {
    font-size: 24px;
    height: 48px;

    @include media("<=tablet") {
      font-size: 16px;
      height: 36px;
    }
  }
}

.ant-modal.forgot-modal-alert {
  position: fixed;
  top: 20%;
  right: 12%;

  @include media("<=tablet-xl") {
    position: fixed;
    top: 10%;
    left: 1%;
    right: 1%;
  }

  & .ant-modal-content {
    border-radius: 30px;
    padding: 96px 56px;

    @include media("<=tablet-xl") {
      border-radius: 12px;
      padding: 8px;
    }

    & .forgot-title {
      margin-bottom: 20px;

      @include media("<=tablet-xl") {
        font-size: 24px;
      }
    }

    & .modal-buttons {
      @include set-display-flex(center, end, 20px);
      justify-self: end;
      & Button {
        height: 38px;
      }
    }
  }
}

.ant-modal.error-modal {
  & .ant-modal-content {
    padding: 0;
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    background-color: map.get($theme-colors, "white-ff");
    border: 1px solid map.get($theme-colors, "white-ffcc");
    border-radius: 4px;
    width: 400px;

    & .ant-alert.ant-alert-with-description {
      padding: 0;
      border: none;
    }
  }
}
