@use "sass:map";

.ant-modal.create-group-modal {
  position: fixed;
  top: 8%;
  right: 25%;

  @include media("<=desktop") {
    position: fixed;
    top: 10%;
    left: 1%;
    right: 1%;
  }

  @include media("<=tablet") {
    top: 10%;
    left: 10%;
    right: 10%;
  }

  & .ant-modal-content {
    border-radius: 30px;
    padding: 12px 64px;
    width: fit-content;
    min-height: 75vh;

    @include media("<=tablet") {
      border-radius: 12px;
      padding: 12px;
      height: 60vh;
    }

    & .ant-modal-body {
      padding: 0;
    }

    & .create-group-title {
      font-size: 36px;
      color: map.get($theme-colors, "primary-text-47");

      @include media("<=tablet") {
        font-size: 24px;
      }
    }

    & .upload-group-photo {
      @include set-display-flex(center, stretch, 0, column);

      & .input-upload-cover {
        font-size: 0px;
        visibility: hidden;
      }

      & .input-upload-cover-btn {
        margin: 0 auto;
        & > svg {
          font-size: 68px;
          border: 1px solid map.get($theme-colors, "black");
          padding: 16px;
          border-radius: 100%;
          margin: 0;

          @include media("<=tablet") {
            font-size: 36px;
            padding: 4px;
          }
        }
      }
    }

    & .create-group-input {
      height: 40px;
      width: 80%;
      display: block;
      background-color: map.get($theme-colors, "gray-alpha-26");
      border-radius: 12px;
      margin: 12px auto;
      color: map.get($theme-colors, "black-alhpa-60");
      font-size: 16px;

      @include media("<=tablet") {
        height: 32px;
        margin: 6px auto;
      }
    }

    & .require-message {
      text-align: center;
      margin: 6px auto;
      color: map.get($theme-colors, "red-button");
    }

    & .create-group-userlist {
      border-radius: 30px;
      border: 1px solid map.get($theme-colors, "gray-alpha-37");
      padding: 16px 0;

      @include media("<=tablet") {
        border-radius: 12px;
        padding: 8px 0;
      }

      & svg {
        color: map.get($theme-colors, "gray-b7");
      }

      & .user-search-container {
        @include set-display-flex(center, space-between);
        margin-bottom: 20px;
        padding: 0 32px;

        & .user-search {
          color: map.get($theme-colors, "gray-text-70");
        }

        & .user-count {
          background-color: map.get($theme-colors, "gray-e3");
          padding: 4px;
          border-radius: 100%;
          color: map.get($theme-colors, "black-alhpa-70");
        }
      }

      & .user-list-items {
        width: 100%;
        padding: 0 32px;
        overflow: auto;
        @include set-display-flex(flex-start, flex-start, 20px, column);
        height: 35vh;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background-color: map.get($theme-colors, "gray-text-c4");
        }

        @include media("<=tablet") {
          padding: 0 16px;
          height: 30vh;
        }

        & .user-list-item {
          width: 100%;
          @include set-display-flex(center, space-between);
          border-bottom: 1px solid map.get($theme-colors, "black-alhpa-49");
          padding-bottom: 5px;

          & .user-info {
            @include set-display-flex(center, flex-start, 8px);
            gap: 8px;

            & .user-name {
              font-size: 16px;
              color: map.get($theme-colors, "black-alhpa-60");
            }
          }

          & .radio-icon > svg {
            font-size: 20px;
            color: map.get($theme-colors, "primary-text-47");
          }
        }
      }
    }

    & .create-group-button {
      width: 60%;
      justify-self: center;
      margin: 24px auto;
      font-size: 18px;
      font-weight: 700;
      height: 35px;
      padding: 6px auto;
      background-color: map.get($theme-colors, "primary-text-19");

      &:hover {
        background-color: map.get($theme-colors, "white");
        color: map.get($theme-colors, "primary-text-19");
      }

      @include media("<=tablet") {
        margin: 12px auto;
      }
    }
  }
}
