// global style guide
@use "sass:map";

// set the line-clamp with the params passed to it
@mixin set-line-clamp($clamp) {
  -webkit-line-clamp: $clamp;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

// set display to flex with the params passed to it
@mixin set-display-flex(
  $align: stretch,
  $justify: flex-start,
  $gap: 0,
  $direction: row
) {
  display: flex;

  @if $align != stretch {
    align-items: $align;
  }

  @if $justify != flex-start {
    justify-content: $justify;
  }

  @if $gap != 0 {
    gap: $gap;
  }

  @if $direction != row {
    flex-direction: $direction;
  }
}

body {
  font-family: map.get($theme-fonts, "primary");
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: map.get($theme-colors, "black");

  @include media("<=tablet") {
    line-height: 20px;
  }

  strong,
  b {
    font-weight: 700;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: map.get($theme-colors, "gray-text-c4");
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
img {
  vertical-align: middle;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

h1 {
  font-family: map.get($theme-fonts, "primary");
  font-size: 72px;
  line-height: 98px;
  font-weight: 700;
  color: map.get($theme-colors, "white");
  text-align: center;
}

h2 {
  font-family: map.get($theme-fonts, "primary");
  font-size: 48px;
  line-height: 66px;
  font-weight: 700;

  &.color-title {
    color: rgba(0, 141, 220, 0.72);
    line-height: 56px;
    font-family: map.get($theme-fonts, "secondary");
  }
  &.extra-bold {
    color: map.get($theme-colors, "white");
    font-weight: 800;
  }
}

h3 {
  font-size: 36px;
  line-height: 49px;
  font-family: map.get($theme-fonts, "primary");
  font-weight: 600;

  &.bold {
    font-weight: 700;
  }
  &.regular {
    font-weight: 400;
  }
}

h4 {
  font-size: 20px;
  line-height: 28px;
  font-family: map.get($theme-fonts, "primary");
  font-weight: 600;

  &.title {
    font-weight: 700;
    font-size: 24px;
  }
  &.subtitle {
    font-size: 18px;
  }
}

.regular-subtitle {
  font-family: map.get($theme-fonts, "primary");
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  &.bold {
    font-weight: 600;
  }
}

.title-color-50 {
  color: map.get($theme-colors, "primary-text-50");
}

.regular-input {
  border: none;
  background: transparent;
  border-bottom: 1px solid #c4c4c4;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.circle-img {
  border-radius: 50%;
}

.regular-icon {
  font-size: 16px;
}

.btn-theme-primary {
  display: inline-block;
  padding: 8px 42px;
  border-radius: 20px;
  transition: all ease 0.25s;

  &.medium-size {
    padding: 12px 48px;
    border-radius: 30px;
  }

  &.filled-btn {
    color: map.get($theme-colors, "white");
    border: 1px solid map.get($theme-colors, "primary");
    background-color: map.get($theme-colors, "primary");

    &:hover {
      color: map.get($theme-colors, "primary");
      background-color: map.get($theme-colors, "transparent");
    }
  }

  &.outlined-btn {
    color: map.get($theme-colors, "primary");
    background-color: map.get($theme-colors, "transparent");
    border: 1px solid map.get($theme-colors, "primary");

    &:hover {
      color: map.get($theme-colors, "black");
      border: 1px solid map.get($theme-colors, "black");
    }
  }
}

.btn-theme-primary-fluid {
  @include set-display-flex(center, center);
  padding: 0;
  width: 100%;
  line-height: 33px;
  border-radius: 50px;
  transition: all ease 0.25s;

  @include media("<=tablet") {
    height: 32px;
  }

  &.default-btn {
    color: map.get($theme-colors, "black");
    border: 0;
    background-color: #e1e1e1;
  }

  &.filled-btn {
    color: map.get($theme-colors, "white");
    border: 1px solid map.get($theme-colors, "primary-button-1b");
    background-color: map.get($theme-colors, "primary-button-1b");

    &:hover,
    &:focus {
      color: map.get($theme-colors, "primary-button-1b");
      background-color: map.get($theme-colors, "transparent");
    }
  }

  &.outlined-btn {
    color: map.get($theme-colors, "primary");
    background-color: map.get($theme-colors, "transparent");
    border: 1px solid map.get($theme-colors, "primary");

    &:hover {
      color: map.get($theme-colors, "black");
      border: 1px solid map.get($theme-colors, "black");
    }
  }
}

.btn-theme-black-normal {
  display: inline-block;
  padding: 12px 36px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 400;
  color: map.get($theme-colors, "black");
  font-family: map.get($theme-fonts, "secondary");
  border: 1px solid map.get($theme-colors, "black");
  transition: all ease 0.25s;
  background-color: map.get($theme-colors, "transparent");

  &:hover {
    color: map.get($theme-colors, "primary");
    border: 1px solid map.get($theme-colors, "primary");
  }

  &.outlined-btn {
    color: map.get($theme-colors, "primary");
    border: 1px solid map.get($theme-colors, "primary");

    &:hover {
      color: map.get($theme-colors, "black");
      border: 1px solid map.get($theme-colors, "black");
    }
  }
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.modal-cross-button {
  content: "X";
  position: absolute;
  border: 0;
  padding: 0px 10px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 100%;
  color: map.get($theme-colors, "orange-text-ff");
  box-shadow: none;
  top: -10px;
  right: -40px;

  &:hover,
  &:focus {
    color: map.get($theme-colors, "orange-text-ff");
  }

  @include media("<=tablet") {
    top: -15px;
    right: -32px;
  }
}

// .ant-popover {
//   z-index: 9;
// }

.ant-popover-inner {
  min-width: 158px;
  border-radius: 10px;
  background-color: map.get($theme-colors, "white-f4");
  box-shadow: 1px 2px 5px map.get($theme-colors, "black-alhpa-25");
}
.ant-popover-arrow {
  display: none;
}
.ant-popover-content {
  @include set-display-flex(center, center, 10, column);
}

.ant-popover-inner-content {
  padding: 0;
}
.modal-logout-button {
  @include set-display-flex(center, space-between);

  & button {
    width: 70px;
    height: 24px;
    font-size: 12px;
  }
}
