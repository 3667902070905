@use "sass:map";

.chatting-header {
  @include set-display-flex(center, space-between, 10);
  background-color: map.get($theme-colors, "white-f1");
  padding: 18px;
  color: map.get($theme-colors, "black");
  position: relative;
  z-index: 1000;

  & .user-info {
    @include set-display-flex(start, start, 10px);

    & .user-img {
      width: 40px;
      height: 40px;
    }

    & .user-name {
      font-size: 18px;
      font-weight: 600;
    }

    & .user-status {
      font-size: 14px;
    }
  }

  & .header-icons {
    @include set-display-flex(center, space-between, 30px);

    & Button {
      background-color: transparent;
      padding: 5px;
      font-size: 20px;
    }
  }

  @include media("<=tablet") {
    width: 100vw;
    padding: 12px 8px;

    & .header-icons {
      gap: 4px;
    }

    & .user-info {
      @include set-display-flex(start, start, 6px);

      & .user-name {
        font-size: 15px;
      }

      & .user-status {
        font-size: 12px;
      }
    }
  }
}
