@use "sass:map";

.sidebar-header {
  & .ant-input-affix-wrapper {
    border-radius: 8px;
    background-color: map.get($theme-colors, "gray-dd");
    // background-color: #e6f0f8;

    & > * {
      background-color: map.get($theme-colors, "gray-dd");
      // background-color: #e6f0f8;
    }
  }

  & .sidebar-icon-container {
    @include set-display-flex(center, end);

    & .sidebar-header-icons {
      @include set-display-flex(center, space-around);
      margin-bottom: 20px;

      & Button {
        padding: 4px;
        font-size: 16px;
        margin: 0;
        background-color: transparent;
      }

      & .dropdown-button {
        border-radius: 6px;
        @include set-display-flex(center, space-around);
        border: 0;
        background-color: map.get($theme-colors, "gray-ea");
        box-shadow: 1px 2px 3px map.get($theme-colors, "black-alhpa-25");
        font-size: 14px;
        margin: 0;
      }
    }
  }

  & .setting-preicon {
    width: 100%;
    justify-content: flex-end;

    & > Button {
      padding-right: 0;

      &:hover,
      &:focus {
        color: map.get($theme-colors, "primary-text-00");
        background-color: transparent;
      }
    }
  }

  & .filter-button {
    border-radius: "10px";
    padding-right: 0;
    margin-right: 0;

    &:hover,
    &:focus {
      color: map.get($theme-colors, "primary-text-00");
      background-color: transparent;
    }
  }
}

.search-user-popover {
  max-height: 320px;
  width: 280px;
  overflow-y: scroll;
  padding: 10px;
  @include set-display-flex(center, flex-start, 8px, column);

  & .user-list-item {
    width: 100%;
    @include set-display-flex(center, flex-start);
    padding-bottom: 5px;

    & .user-info {
      @include set-display-flex(center, flex-start, 8px);

      & .user-name {
        font-size: 14px;
        color: map.get($theme-colors, "black-alhpa-60");
      }
    }
  }
}
