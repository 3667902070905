@use "sass:map";

.message-card {
  width: 100%;

  & .message-sender-img {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: fit-content;
  }

  & .message-time {
    font-size: 12px;
    color: map.get($theme-colors, "gray-text-8b");
  }

  & .messages-area {
    display: flex;
    flex-direction: column;
  }

  & .message-body {
    width: 100%;
    @include set-display-flex(stretch, flex-start, 10px);

    & .message-options {
      position: relative;
      @include set-display-flex(flex-start, space-between, 0, column);
      margin-top: 0;

      & .ant-btn {
        height: 15px;
      }

      & > Button {
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          background: transparent;
        }
      }
    }
  }

  & .message-right {
    justify-content: flex-end;
  }

  & .message-left {
    justify-content: flex-start;
  }

  & .reaction-count {
    background-color: map.get($theme-colors, "white");
    width: fit-content;
    box-shadow: 1px 2px 5px map.get($theme-colors, "black-alhpa-25");
    border-radius: 22px;
    padding: 1px 6px;
    @include set-display-flex(center, space-between, 2px);
    position: absolute;
    bottom: -10px;
    left: 15px;

    & .icon {
      font-size: 14px;
    }
  }

  & .text-message {
    position: relative;
    // background-color: map.get($theme-colors, "white-f1");
    background-color: #f2f6f9;
    width: 95%;
    padding: 20px;
    // border: 0.5px solid map.get($theme-colors, "gray-text-bc");
    border-radius: 22px;

    & .message-reply-text {
      color: map.get($theme-colors, "gray-text-60");
      @include set-line-clamp(2);
    }

    & .reply-message-author {
      font-size: 14px;
      color: map.get($theme-colors, "gray-text-9b");
      padding-top: 4px;
    }

    & .message-reply-divider {
      margin: 14px 0;
      background-color: map.get($theme-colors, "gray-text-bc");
      height: 2px;
    }

    & .message-text {
      // color: map.get($theme-colors, "gray-text-60");
      color: map.get($theme-colors, "black");
      word-wrap: break-word;
    }
  }

  & .text-message.sender-message {
    background-color: #dbf1ff;
  }

  @include media("<=tablet") {
    width: 100vw;
    padding: 0 10px;
  }
}
.message-card-bubble {
  width: 100%;

  & .bubble-images {
    @include set-display-flex(center, flex-end, 4px);

    & .message-card-bubble-btn {
      margin-top: 5px;
      font-size: 16px;
      text-align: center;
      padding: 0;
      color: map.get($theme-colors, "gray-text-c4");
    }

    & .profile-text-avatar {
      width: 16px;
      height: 16px;
      font-size: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: map.get($theme-colors, "orange-text-ea");
      color: map.get($theme-colors, "white");
      border-radius: 100%;
      text-align: center;
    }
  }
}

.message-options-popover {
  @include set-display-flex(flex-start, flex-start, 12px, column);
  padding: 12px;

  & > Button {
    font-size: 16px;
    text-align: left;

    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

.message-react {
  padding: 4px 8px;
  font-size: 24px;
  display: flex;
  gap: 4px;
  align-items: center;

  & .icon {
    font-size: 32px;
    border: none;
    background-color: transparent;
    transition: transform 0.5s;
    transform: scale(1);

    &:hover {
      transform: scale(1.5);
    }
  }
}

.reaction-view-popover {
  max-height: 60%;
  overflow-y: auto;
  padding: 8px 12px;
  @include set-display-flex(space-between, flex-start, 8px, column);

  & .user-list-item {
    @include set-display-flex(flex-start, space-between, 16px);

    & .user-info {
      @include set-display-flex(flex-start, flex-start, 8px);
    }
  }
}

.Container.link-preview {
  margin: 16px 0 16px auto !important;

  & .LowerContainer {
    & > .Title {
      font-size: 16px;
      line-height: normal;
      @include set-line-clamp(2);
    }

    & .Description.Secondary {
      @include set-line-clamp(3);
      font-size: 15px;
      color: map.get($theme-colors, "gray-text-9b");
    }

    & .SiteDetails {
      @include set-line-clamp(1);
      font-size: 14px;
    }
  }
}
