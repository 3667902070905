// global style guide
@use "sass:map";

.sidebar-container {
  background-color: map.get($theme-colors, "white-f1");
  height: 100vh;
  padding: 8px;

  & .online-users {
    width: 100%;
    height: fit-content;
    display: flex;
    overflow-y: hidden;
    padding-bottom: 8px;
    overflow-x: auto;
    // scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 4px;
      // display: none;
    }

    & .online-user-group {
      gap: 10px;

      & .all-user-button {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        text-align: center;
        border: 1px solid map.get($theme-colors, "white");
        background-color: map.get($theme-colors, "gray-text-c4");
        font-size: 12px;
        color: map.get($theme-colors, "black-2d");

        &::before {
          content: "";
          border-radius: 100%;
          position: absolute;
          background-color: map.get($theme-colors, "green-017");
          border: 1px solid map.get($theme-colors, "white");
          width: 8px;
          height: 8px;
          bottom: 4px;
          right: 1px;
        }
      }

      & .online-user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 0;
      }
    }
  }

  & .sidebar-card-container {
    height: 100%;
    overflow: auto;
    padding-right: 4px;
    padding-bottom: 50px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: map.get($theme-colors, "gray-text-c4");
    }

    & .sidebar-cards {
      @include set-display-flex(flex-start, flex-start, 8px, column);
      padding-bottom: 200px;

      & .focushed {
        border: 1px solid map.get($theme-colors, "green-017");
      }

      & .sidebar-card {
        background-color: map.get($theme-colors, "white-fa");
        width: 100%;
        border-radius: 8px;

        &:focus-within {
          border: 1px solid map.get($theme-colors, "green-017");
        }

        & .unread-card {
          color: map.get($theme-colors, "black");
        }

        & .read-card {
          color: map.get($theme-colors, "gray-text-7c");
        }

        & .ant-card-body {
          padding: 10px;
        }

        & .sidebar-car-message {
          font-size: 14px;
          font-weight: 600;
          @include set-line-clamp(1);
        }

        & .card-message-count {
          font-size: 12px;
          display: inline-block;
          padding: 2px 8px;
          border-radius: 50%;
          background-color: map.get($theme-colors, "orange-text-ff");
          color: map.get($theme-colors, "white");
        }

        & .message-status-icon {
          font-size: 20px;
          color: map.get($theme-colors, "gray-text-bc");
        }

        & .message-status-img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }

        & .sidebar-card-img {
          width: 60px;
          height: 60px;
        }
      }
    }

    @include media("<=tablet") {
      padding-bottom: 100px;
    }
  }

  @include media("<=tablet") {
    overflow-y: hidden;
    width: 100vw;
    height: 100vh;
    display: none;
  }
}

.sidebar-user {
  @include set-display-flex(center, flex-start, 12px);
}

.sidebar-user-name {
  font-size: 20px;
  font-weight: 600;
  color: map.get($theme-colors, "black");
}

.sidebar-card-user-name {
  font-size: 20px;
  font-weight: 600;
  @include set-line-clamp(1);
}

.ant-dropdown-menu {
  border-radius: 18px;

  & .ant-dropdown-menu-item {
    background-color: transparent;

    & .ant-btn-link {
      color: map.get($theme-colors, "black");

      &:hover,
      &:focus,
      &:active {
        color: map.get($theme-colors, "primary-text-00");
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.ant-modal.join-meeting-modal {
  position: fixed;
  top: 30%;
  right: 25%;

  @include media("<=tablet-xl") {
    position: fixed;
    top: 10%;
    left: 10%;
    right: 12%;
  }

  & .ant-modal-content {
    border-radius: 30px;
    padding: 48px 64px;
    width: fit-content;

    @include media("<=tablet-xl") {
      border-radius: 12px;
      padding: 8px;
    }

    & .join-meeting-title {
      margin-bottom: 20px;
      font-size: 48px;
      font-weight: 700;
      color: map.get($theme-colors, "primary-text-00");

      @include media("<=tablet-xl") {
        font-size: 24px;
      }
    }

    & .join-meeting-subtitle {
      font-size: 14px;
      color: map.get($theme-colors, "black-3f");
    }

    & .join-meeting-input {
      height: 56px;
      background-color: map.get($theme-colors, "gray-text-ea");
      padding: 16px;
      border-radius: 18px;
      border: 0;

      & .ant-input {
        background-color: map.get($theme-colors, "gray-text-ea");
        font-size: 20px;
        color: map.get($theme-colors, "gray-alpha-17");
      }

      & .ant-input-suffix {
        font-size: 24px;
      }
    }

    & .join-meeting-button {
      width: 60%;
      justify-self: center;
      margin: 24px auto;
      font-size: 18px;
      font-weight: 700;
      height: 35px;
      padding: 6px auto;
      background-color: map.get($theme-colors, "primary-text-19");

      &:hover {
        background-color: map.get($theme-colors, "white");
        color: map.get($theme-colors, "primary-text-19");
      }
    }
  }
}

.ant-tooltip-arrow {
  display: none;
}

.ant-tooltip-placement-top {
  padding-bottom: 4px;
}
.ant-popover-placement-bottomLeft {
  padding-top: 0;
}

.card-bubble-img {
  @include set-display-flex(center, flex-end, 4px);
}
