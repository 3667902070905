@use "sass:map";

.group-chat-content {
  min-height: 82vh;
  padding-bottom: 14px;
  background-color: map.get($theme-colors, "white");

  & .previous-btn-container {
    width: 100%;
    @include set-display-flex(flex-start, center);
    padding-top: 10px;

    & .previous-btn {
      padding: 0 8px;
      background-color: map.get($theme-colors, "white-f1");
      height: fit-content;
      border-radius: 15px;
    }
  }

  // @include media("<=tablet") {
  //   height: 60vh;
  //   overflow-y: auto;
  //   overflow-x: hidden;
  // }
}
.ant-drawer.group-profile {
  & > .ant-drawer-mask {
    background-color: transparent;
  }

  & .ant-drawer-header {
    height: 80px;

    & .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
  }

  & .ant-drawer-body {
    background-color: map.get($theme-colors, "white-f1");
    & .group-profile-info {
      width: 100%;

      & .custom-avatar {
        width: 100%;
        text-align: center;

        & .ant-avatar {
          border: 3px solid map.get($theme-colors, "white");
        }
      }

      & .group-profile-name {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        text-transform: capitalize;
        line-height: 40px;
      }
    }
  }
}
